<template>
  <div id="rptlist">
    <mt-header fixed :title="(querytype==1?'两癌筛查':'孕优')+'报告列表'">
      <mt-button slot="left" style=" width: 90px"
                 @click="back()"      icon="back">返回</mt-button>

    </mt-header>


<div class="content">
  <mt-cell v-for="( item,index ) in  datalist"  :label="item.JiandangRQ"
 is-link
           v-on:click.native="rptclick(item)"
           :title="(querytype==1?'两癌':'孕优')+'查体报告单'"   :key="index">

    <img slot="icon" src="@/assets/tabbar/consulting_selected.png" width="24" height="24"> <span>报告查看</span>
  </mt-cell>
</div>


  </div>

</template>
<script>
import {Indicator, Toast} from 'mint-ui';
// import axios from "axios";
// import axios from "axios";

import DialogUtil from "@/common/DialogUtil";

  export default {
  name: "rptlist",
  data() {
    return {
      querytype:'',
      pathinfo:'',
      form: {
        Xingming: '张秀芝',
        Shenfenzheng: '370306195909133528',
        Leixing: ''
      },
      datalist:[],
    };
  },
  created(){
    this.querytype=this.$route.query.type
    this.datalist=JSON.parse( this.$route.query.data);
   // Toast(JSON.stringify(  this.datalist))
  },
  methods: {
    rptclick(item){
    //  Toast(JSON.stringify(item));
      let that = this;
      Indicator.open({text:'加载...',spinnerType:'fading-circle'})
      let hisurl='/api/screendisplay/YYT_Report_DataSend'
      //let hisurl='http://120.224.143.122:8098/LASCdataShow.dll/hospital/interface/YYT_Report_DataSearch'
      // let hisurl='http://8.140.140.112:6060/api/kanbaninfo/getconfigdata'
      this.$axios.post(hisurl,item).then((res) => {
        let code=res.data.code;
        that.pathinfo=res.data.pathinfo;
        let filename=res.data.filename;
       // let respData = res.data.data
       //Toast(JSON.stringify( respData))
        Indicator.close()
         //Toast(that.pathinfo);
        if (code==='10001')
           this.$router.push({ path:'/hospital/report/rptviewer', query:{url:that.pathinfo
             ,type:that.querytype,filename:filename,rn:DialogUtil.randomn(8)}});
         else {
           Toast('登录失败:'+res.data.msg);
         }
      }).catch(err => {
        Indicator.close()
        Toast(JSON.stringify( err));
      }).finally(() => {
        Indicator.close()
      })
    },
    back() {
      this.$router.go(-2);
    },
  }
};
</script>
<style scoped>
.content {
margin-top: 50px;
}
.mint-header {
  font-size: 18px;
  background-color: #eb89a9;
}
</style>